export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: "menuitems.dashboards.text",
        icon: "bx-home-circle",
        link: "/ss/admin/dashboard",
    },
    
    {
        id: 4,
        label: "menuitems.billings.text",
        icon: "bx bx-receipt",
        link: "/ss/admin/payment/billings",
        roleAllowed: ['internal_ss', 'sales_ss_dev']
    },
    {
        id: 3,
        label: "menuitems.simulation.text",
        icon: "bx bx-list-ul",
        link: "/ss/admin/payment/simulation",
        roleAllowed: ['internal_ss', 'sales_ss_dev']
    },

];

